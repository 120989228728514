<template>
  <v-form ref="form" lazy-validation>
    <v-dialog
      v-model="dialog"
      max-width="600px"
      persistent
      transition="dialog-top-transition"
      @keydown.esc="close"
    >
      <v-card>
        <br />
        <v-card-text>
          <v-row dene>
            <v-col cols="6" sm="6" md="6">
              <v-select
                clearable
                v-model="failure_id"
                :items="failures_list"
                label="Failure"
                item-text="failure"
                item-value="failure_id"
                :rules="[(v) => !!v || 'Failure required']"
                outlined
                dense
                @change="select"
                return-object
              ></v-select>
            </v-col>
            <v-col cols="6" sm="6" md="6">
              <v-select
                clearable
                v-if="showformation"
                v-model="formation_id"
                :items="formations_list"
                label="Formation"
                item-text="formationname"
                item-value="formation_id"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            @click.stop="save_wmf"
            :disabled="change == 0"
            class="ma-1"
          >
            Save
          </v-btn>
          <v-btn color="blue darken-1" @click.stop="closeBarriers" class="ma-1">
            close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirmdialog ref="confirm" />
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </v-form>
</template>
<script>
export default {
  components: {
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
  },
  props: {
    dialog: Boolean,
    failures_list: [],
    components_list: [],
    formations_list: [],
    component: {},
    barriers: {},
  },
  data() {
    return {
      change: 0,
      snackbar: false,
      changes: false,
      formation_id: null,
      failure_id: null,
      snackbar_timeout: 2000,
      snackbar_text: "",
      snackbar_color: "primary",
      selected_failure_id: -1,
      selected_external: 0,
      selected_code: "",
      selected_value: 0,
      showformation: false,
      failures_MASP: ["L", "M", "S", "T"],
    };
  },
  //  watch: {
  //     dialog: {
  //       handler() {
  //         if (this.dialog == true) {
  //           this.list = [];
  //           this.headerIndex = 1;

  //           this.wmf = Object.assign({}, this.barriers);

  //           this.wmf.failure_id = null;

  //           this.change = 0;
  //           this.selected_failure = "";
  //           this.selected_code = "";
  //           this.taux = 100;
  //           // this.getFailures();
  //         }
  //       },
  //     },
  //   },
  async mounted() {
    this.formation_id = null;
    this.showformation = false;
    this.selected_failure_id = -1;
    this.selected_external = 0;
    this.selected_code = "";
    this.selected_value = 0;
  },
  async created() {},
  methods: {
    select(item) {
      this.formation_id = null;
      this.failure_id = null;
      this.change = 0;
      this.showformation = false;
      if (item) {
        this.selected_value = item.value;
        this.selected_failure_id = item.failure_id;
        this.selected_external = item.external;
        if (this.failures_MASP.filter((x) => x == item.code).length > 0)
          this.showformation = true;
        this.change = 1;
      }
    },

    closeBarriers: function () {
      this.changes = false;
      this.$emit("close");
    },
    async save_wmf() {
      let item = {
        cmp_id: this.component.id,
        cmp_code: this.component.code,
        external: this.selected_external,
        failure_id: this.selected_failure_id,
        failure_coef: this.selected_value,
        formation_id: this.formation_id,
      };
      this.$emit("close", item);
    },
  },
};
</script>
